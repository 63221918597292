
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { CarIllegalAdd } from '@/types/carManage'

@Component({
  name: 'AddMaintain'
})
export default class extends Vue {
  private info: CarIllegalAdd = {
    projectId: '',
    machineId: '',
    violationTime: '',
    violationAddress: '',
    handleContent: '',
    typeName: '',
    brand: '',
    model: ''

  }

  private submitShow = false
  private disabledMachineName =true
  private machineList: CarIllegalAdd[] = []

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    machineId: [{ required: true, message: '请选择车牌号', trigger: 'change' }],
    violationAddress: [{ required: true, message: '请输入违章地点', trigger: 'blur' }],
    violationTime: [{ required: true, message: '请选择违章时间', trigger: 'change' }]

  }

  get violationId () {
    return this.$route.params.id as string || ''
  }

  get carTypeList () {
    return this.$store.state.carTypeList
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    if (this.violationId) {
      this.getDetail(this.violationId)
    }
  }

  getDetail (violationId: string) {
    this.$axios
      .get(this.$apis.carManage.selectVehicleViolationById, { violationId })
      .then((res) => {
        this.info = res
        this.getMachineList()
      })
  }

  // 项目改变
  changeProject (value: string) {
    this.info.machineId = ''
    this.info.typeName = ''
    this.info.brand = ''
    this.info.model = ''

    this.disabledMachineName = !value
    value && this.getMachineList()
  }

  changeMachine (value: string) {
    const res = this.machineList.find((item) => item.machineId === value)
    if (res) {
      this.info.typeName = res.typeName
      this.info.brand = res.brand
      this.info.model = res.model
    }
  }

  // 车牌号
  getMachineList () {
    this.$axios
      .get(this.$apis.carManage.selectMachineList, {
        projectId: this.info.projectId
      })
      .then((res) => {
        this.machineList = res || []
      })
  }

  saveForm () {
    ;(this.$refs.form as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const urls = this.$route.params.id
          ? this.$apis.carManage.updateVehicleViolation
          : this.$apis.carManage.insertVehicleViolation
        // 不需要传这三个参数
        delete this.info.typeName
        delete this.info.brand
        delete this.info.model
        this.$axios
          .post(urls, this.info)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.back()
          })
          .finally(() => {
            this.submitShow = false
          })
      }
    })
  }
}
